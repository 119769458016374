import { Leaves } from "src/utils/type_utils";
import {
    Application, ArtworkOfferedForBuybackDescription,
    ProjectCostItem, ProjectPersonWithBiography, RequestedProjectCostItem,
} from "src/entities/Application/Application";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";
import { getYear } from "date-fns";

export interface IApplicationValueForm {
    totalValue?: string,
    requestedValue?: string,
}

export interface IApplicationForm {
    name?: string | null,
    description?: string | null,
    projectCostsTable?: ProjectCostItem[],
    requestedProjectCostsTable?: RequestedProjectCostItem[],
    listOfProjectAuthorsWithBiographies?: ProjectPersonWithBiography[],
    listOfProjectParticipantsWithBiographies?: ProjectPersonWithBiography[]
    realizationTimeFrom?: string | null,
    realizationTimeTo?: string | null,
    competition?: string,
    value?: IApplicationValueForm,
    legalApplicant?: string | null,
    naturalApplicant?: string | null,
    detailedProjectDescription?: string[],
    shortDescriptionOfOfferedArtworkForBuyback?: ArtworkOfferedForBuybackDescription[]
    artworkArtistBiography?: string | null,
    artworkArtistDiploma?: string | null,
    artworkDetailedDescription?: string | null,
    statement?: string | null,
    noOtherDocumentation?: boolean | null,
    otherDocumentation?: string[],
    financialPlan?: string[],
    recommendationsFromInstitutions?: string[],
    statementsFromAuthorizedPeople?: string[],
    experienceOfProjectProponent?: string[],
    invitationFromRelevantInstitution?: string | null,
    detailedBudget?: string | null,
    detailedBudgetTable?: ProjectCostItem[],
    noProjectProponent?: boolean | null,
    listOfProjectAuthors?: string[],
    noProjectAuthors?: boolean | null,
    noFinancialPlan?: boolean | null,
}

export type IApplicationFormError = Partial<Record<Leaves<IApplicationForm>, string>>;

export type IApplicationFormErrors = Partial<Record<Leaves<IApplicationForm>, string>>

export const defaultArtworkOfferedForBuybackDescription: ArtworkOfferedForBuybackDescription[] = [{
    id: v4(),
    amount: "0",
    name: "",
    dimensions: "",
    technique: "",
    year: getYear(new Date()),
}];

export const defaultDetailedBudgetTable: ProjectCostItem[] = [{
    id: v4(),
    amount: "0",
    costDescription: "",
    fundingSource: "",
}];

export const defaultApplicationProjectCostsTable: ProjectCostItem[] = [{
    id: v4(),
    amount: "0",
    costDescription: "",
    fundingSource: "",
}];

export const defaultApplicationRequestedProjectCostsTable: RequestedProjectCostItem[] = [{
    id: v4(),
    amount: "0",
    costDescription: "",
}];

export const defaultProjectPersonWithBiography: ProjectPersonWithBiography[] = [{
    id: v4(),
    biography: "",
    name: "",
}];


export class ApplicationFormUtils {

    static createForm(application: Application | null): IApplicationForm {
        if (!application) {
            return {};
        }

        return {};
    }

    static validateForm(form: IApplicationForm, isEdit: boolean): IApplicationFormErrors {
        const errors = {};

        return errors;
    }


    static prepareForm(form: IApplicationForm): IApplicationForm {
        const clonedForm = cloneDeep(form);

        if (JSON.stringify(clonedForm.detailedBudgetTable) === JSON.stringify(defaultDetailedBudgetTable)) {
            clonedForm.detailedBudgetTable = [];
        }

        if (JSON.stringify(clonedForm.shortDescriptionOfOfferedArtworkForBuyback) === JSON.stringify(defaultArtworkOfferedForBuybackDescription)) {
            clonedForm.shortDescriptionOfOfferedArtworkForBuyback = [];
        }

        if (JSON.stringify(clonedForm.projectCostsTable) === JSON.stringify(defaultApplicationProjectCostsTable)) {
            clonedForm.projectCostsTable = [];
        }

        if (JSON.stringify(clonedForm.requestedProjectCostsTable) === JSON.stringify(defaultApplicationRequestedProjectCostsTable)) {
            clonedForm.requestedProjectCostsTable = [];
        }

        if (JSON.stringify(clonedForm.listOfProjectAuthorsWithBiographies) === JSON.stringify(defaultProjectPersonWithBiography)) {
            clonedForm.listOfProjectAuthorsWithBiographies = [];
        }

        if (JSON.stringify(clonedForm.listOfProjectParticipantsWithBiographies) === JSON.stringify(defaultProjectPersonWithBiography)) {
            clonedForm.listOfProjectParticipantsWithBiographies = [];
        }

        return {
            ...clonedForm,
        };
    }
}
