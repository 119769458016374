import React, { MouseEventHandler } from "react";
import classnames from "classnames";
import "./iconsax.scss";
import { WithStyleAndClassname } from "../../utils/type_utils";

export interface IconSaxProps {
    icon: string
    size?: number
    color?: string
    readonly onClick?: MouseEventHandler<HTMLButtonElement>,
}

function IconSax({
                     icon,
                     className,
                     style,
                     size,
                     color,
                     onClick,
                     ...props
                 }: WithStyleAndClassname<IconSaxProps>) {

    const classNames = classnames("isax", `isax-${icon}`, className);

    return (
        <i className={classNames}
           style={{ ...style, fontSize: size + "px", color: color }}
           onClick={onClick}
           {...props}/>
    );
}

export default IconSax;
