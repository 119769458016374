import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "./slices/reducers";
import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
