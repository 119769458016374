import { SelectFieldOption } from "nexon-react-ui";

export const IS_DEV = import.meta.env.NODE_ENV === "development";
export const SERVER_ENV = import.meta.env.VITE_SERVER_ENV || "dev";
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const ACCEPT_ATTACHMENTS = ".jpg, .png, .jpeg, .pdf, .doc, .docx, video/mp4, video/quicktime";
export const ACCEPT_ATTACHMENTS_OFFERS = ".jpg, .png, .jpeg, .pdf";

export const ACCEPT_ATTACHMENTS_DOCUMENT_TYPED = {
    "image/jpeg": [],
    "image/png": [],
    "application/pdf": [],
    "application/msword": [], // doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], //docx
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const ACCEPT_ATTACHMENTS_COMPETITION_TYPED = {
    "image/jpeg": [],
    "image/png": [],
    "application/pdf": [],
    "application/msword": [], // doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], //docx
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const ACCEPT_ATTACHMENTS_TYPED = {
    "image/jpeg": [],
    "image/png": [],
    "application/msword": [], // doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], //docx
    "video/mp4": [],
    "video/quicktime": [],
};

export enum Errors {
    ERR_MISSING_DATA = "ERR_MISSING_DATA",
    ERR_FORBIDDEN_MEDIA_OBJECT_EXTENSION = "ERR_FORBIDDEN_MEDIA_OBJECT_EXTENSION",
    ERR_IMAGE_RESOLUTION_TOO_BIG = "ERR_IMAGE_RESOLUTION_TOO_BIG",
    ERR_ENTITY_UPDATES_FORBIDDEN = "ERR_ENTITY_UPDATES_FORBIDDEN",
    ERR_ENTITY_DOES_NOT_EXIST = "ERR_ENTITY_DOES_NOT_EXIST",
    ENTITY_OBJECT_NOT_FOUND = "ENTITY_OBJECT_NOT_FOUND",
    ERR_BAD_PAYLOAD = "ERR_BAD_PAYLOAD",
    ERR_NATURAL_PERSON_CANT_APPLY = "ERR_NATURAL_PERSON_CANT_APPLY",
    ERR_LEGAL_PERSON_CANT_APPLY = "ERR_LEGAL_PERSON_CANT_APPLY",
    ERR_NOT_ALLOWED_TO_SET_STATUS = "ERR_NOT_ALLOWED_TO_SET_STATUS",
    PASSWORD_IS_MISSING = "PASSWORD_IS_MISSING",
    ERR_MISSING_REQUIRED_DOCUMENTS = "ERR_MISSING_REQUIRED_DOCUMENTS",
    ERR_COMPETITION_EXPIRED = "ERR_COMPETITION_EXPIRED",
}

export interface GroupedOption {
    readonly label: string;
    readonly options: readonly SelectFieldOption[];
}
