import { Router } from "./router/router";
import "./i18n/config";
import { matchPath, Navigate, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";
import Header from "./components/Header/Header";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Login from "src/pages/Login/Login";
import React, { Suspense, useEffect, useState } from "react";
import { RegisterFormStorageKeys, StorageKeys } from "./utils/storage_keys";
import { loginActions, loginAsyncActions } from "./store/slices/loginSlice";
import { Button, Spinner } from "nexon-react-ui";
import { useTranslation } from "react-i18next";
import "./assets/themes.scss";
import classNames from "classnames";
import { loginSelectors } from "./store/slices/loginSelectors";
import { UserRole } from "./entities/User/User";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import HeaderSvg from "src/assets/images/FMSLU_Header.svg";
import { ReactComponent as SvgLogo } from "./assets/images/fmslu.svg";
import useMediaQuery from "src/utils/hooks/useMediaQuery";
import { ApplicationSettingsApi } from "src/api/application_settings";
import ReloadPage from "./pages/ReloadPage/ReloadPage";

const Support = React.lazy(() => import ("src/pages/Support/Support"));
const Competitions = React.lazy(() => import ("src/pages/Competitions/Competitions"));
const Documents = React.lazy(() => import ("src/pages/Documents/Documents"));
const Applications = React.lazy(() => import ("src/pages/Applications/Applications"));
const MyProfile = React.lazy(() => import ("src/pages/MyProfile/MyProfile"));
const Register = React.lazy(() => import("src/pages/Register/Register"));
const ApplyToCompetition = React.lazy(() => import("src/pages/ApplyToCompetition/ApplyToCompetition"));

function whereShouldIGo(roles: UserRole[]): string {
    return Router.RoutePaths.Competitions;
}

function AppSpinner() {
    return (
        <div className="spinner-container">
            <Spinner/>
        </div>
    );
}

function App() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const user = useAppSelector(loginSelectors.user);
    const effectiveUser = useAppSelector(loginSelectors.effectiveUser);
    const [async, setAsync] = useState(true);
    const [appSettingsAsync, setAppSettingsAsync] = useState(true);
    const isMobile = useMediaQuery("only screen and (max-width: 768px)");

    const renderMenuHeader = ![Router.RoutePaths.Login].some(r => matchPath({ path: r }, location.pathname));

    const renderLogin = !Boolean(user);

    const theme = useAppSelector(state => state.theme.theme);

    const roles = effectiveUser?.roles ?? [];

    useEffect(() => {
        const body = document.querySelector("body");
        if (body) {
            body.className = `theme-${theme}`;
        }
    }, [theme]);

    useEffect(() => {
        const reloadPage = localStorage.getItem("reload-page");
        if (!reloadPage) return;
        localStorage.removeItem("reload-page");

        window.location.href = reloadPage;
    }, []);

    useEffect(() => {
        ApplicationSettingsApi.fetch().then(applicationSettings => {
            dispatch(loginActions.setSettings(applicationSettings));
        }).finally(() => setAppSettingsAsync(false));
    }, []);

    useEffect(() => {
        const token = localStorage.getItem(StorageKeys.Token);

        if (user) {
            setAsync(false);
            return;
        }

        if (!token) {
            setAsync(false);
        }

        if (token && !user) {
            dispatch(loginAsyncActions.getMe()).finally(() => setAsync(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user]);

    useEffect(() => {
        const isRegisterPage = localStorage.getItem("register-page");

        if (isRegisterPage && location.pathname !== "/register") {
            Object.values(RegisterFormStorageKeys).forEach(storageKey => {
                localStorage.removeItem(storageKey);
            });

            localStorage.removeItem("register-page");
        }
    }, [location]);

    if (isMobile) {
        return (
            <div className="app flex-column flex-center-both flex-1 h-100p">
                <div className="mb-28">
                    <SvgLogo className={"app-logo"}/>
                </div>
                <div>
                    Molimo otvorite aplikaciju na kompjuteru/laptopu
                </div>
            </div>
        );
    }

    if (async || appSettingsAsync) {
        return (
            <div className="App">
                <div className="app-spinner-container">
                    <Spinner/>
                </div>
            </div>
        );
    }

    if (location.pathname === "/register") {
        return (
            <Suspense
                fallback={
                    <div className="App">
                        <div className="app-spinner-container">
                            <Spinner/>
                        </div>
                    </div>
                }>
                <Register/>
            </Suspense>
        );
    }

    if (renderLogin) {
        return <Login/>;
    }

    return (
        <div className={classNames("App")}>
            <img height={48} width="100%" style={{ objectFit: "cover" }} src={HeaderSvg}/>

            {renderMenuHeader && (
                <Header/>
            )}

            <div className="app-content-container">
                <div className="app-content">
                    <Routes location={location} key={location.pathname}>
                        <Route path={Router.RoutePaths.NoBoard} element={
                            <div className="text-white">
                                {t<string>("you_dont_have_access_to_any_boards")}
                            </div>
                        }/>

                        <Route path={Router.RoutePaths.Competitions} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <Competitions/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route path={Router.RoutePaths.Applications} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <Applications/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route path={Router.RoutePaths.Documents} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <Documents/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route path={Router.RoutePaths.MyProfile} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <MyProfile/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route path={Router.RoutePaths.Support} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <Support/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route path={Router.RoutePaths.HiddenBugRoute} element={
                            <Button onClick={() => {
                                throw new Error("Bug sentry");
                            }}>
                            </Button>
                        }/>

                        <Route path={Router.RoutePaths.ReloadPage} element={<ReloadPage/>}/>

                        <Route path={Router.RoutePaths.ApplyToCompetitionWithApplicationId} element={
                            <ErrorBoundary>
                                <Suspense fallback={<AppSpinner/>}>
                                    <ApplyToCompetition/>
                                </Suspense>
                            </ErrorBoundary>
                        }/>

                        <Route
                            path="/"
                            element={<Navigate to={whereShouldIGo(roles)} replace/>}
                        />

                        <Route
                            path={"*"}
                            element={<div className="text-white">Ruta nije pronađena</div>}
                        />
                    </Routes>
                </div>
            </div>
            <div id="modal-node"/>
        </div>
    );
}

export default App;
