import React, { useMemo } from "react";
import "./navbar.scss";
import { RouteName, Router } from "src/router/router";
import { useTranslation } from "react-i18next";
import { API } from "src/api/api";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

export interface NavbarItem {
    routeName: RouteName;
    routePath: string;
    title: string;
}

function Navbar({}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();

    const match = Router.routes.find((r) => matchPath({
        path: r.path,
    }, location.pathname)) ?? null;

    const navbarItems: NavbarItem[] = useMemo(() => {
        return [
            {
                routeName: RouteName.Competitions,
                routePath: Router.RoutePaths.Competitions,
                title: t("competitions"),
            }, {
                routeName: RouteName.Applications,
                routePath: Router.RoutePaths.Applications,
                title: t("my_applications"),
            }, {
                routeName: RouteName.Documents,
                routePath: Router.RoutePaths.Documents,
                title: t("my_documents"),
            }, {
                routeName: RouteName.MyProfile,
                routePath: Router.RoutePaths.MyProfile,
                title: t("my_profile"),
            }, {
                routeName: RouteName.Support,
                routePath: Router.RoutePaths.Support,
                title: "Podrška",
            },
        ];
    }, [t]);

    if (match === null) return null;

    const route = Router.routes.find(({ path }) => {
        return match.path === path;
    });

    return (
        <div className="navbar-container">
            {navbarItems.map((navbarItem, index) => {
                let isActive = navbarItem.routePath === route?.path;

                if (route?.path.includes("apply-to-competition") && navbarItem.routePath === Router.RoutePaths.Applications) {
                    isActive = true;
                }

                return (
                    <div key={index}
                         className={classNames("navbar-item", { active: isActive })}
                         onClick={() => {
                             navigate(navbarItem.routePath);
                         }}>
                        {navbarItem.title}
                    </div>
                );
            })}
            <div className="navbar-item"
                 onClick={() => {
                     API.logOut();
                 }}>
                {t<string>("logout")}
            </div>
        </div>
    );
}

export default Navbar;
