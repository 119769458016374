import { API, ApiMethod } from "src/api/api";
import { ApplicationSettings } from "src/entities/ApplicationSettings/ApplicationSettings";

export class ApplicationSettingsApi {
    static async fetch(): Promise<ApplicationSettings | null> {
        return API.jsonApiCall<ApplicationSettings>({
            url: API.applicationSettings,
            method: ApiMethod.Get,
            noAuth: true,
        });
    }
}
