import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncAction } from "src/store/helpers/entityReducer";
import i18 from "../../i18n/config";
import { IUserForm } from "src/entities/User/UserForm";
import { LoginApi } from "src/api/login";
import { CompoundUser, isUserLegalPerson } from "src/entities/CompoundUser/CompoundUser";
import { ILegalPersonForm } from "src/entities/LegalPerson/ILegalPersonForm";
import { LegalPerson } from "src/entities/LegalPerson/LegalPerson";
import { INaturalPersonForm } from "src/entities/NaturalPerson/INaturalPersonForm";
import { NaturalPerson } from "src/entities/NaturalPerson/NaturalPerson";
import { ApplicationSettings } from "src/entities/ApplicationSettings/ApplicationSettings";
import * as Sentry from '@sentry/browser'

export interface LoginErrors {
    email?: string,
    password?: string,
    generic?: string,
}

export interface LoginState {
    loginAsync: boolean,
    userAsync: boolean,
    editAsync: boolean,
    passwordAsync: boolean,
    loginErrors: LoginErrors,
    user: CompoundUser | null,
    settings: ApplicationSettings | null,
}

const initialState: LoginState = {
    loginAsync: false,
    editAsync: false,
    userAsync: false,
    passwordAsync: false,
    loginErrors: {},
    user: null,
    settings: null,
};

const performLogin = (email: string, password: string): AsyncAction<Promise<boolean>> => {
    return async (dispatch) => {

        dispatch(loginActions.setLoginAsync(true));

        return await LoginApi.doLogin(email, password)
            .then(() => dispatch(getMe()))
            .catch((r) => {
                if (r.status === 401) {
                    dispatch(loginActions.setLoginErrors({ generic: `${i18.t<string>("invalid_password_or_email_please_verify_your_credentials")}` }));
                } else {
                    dispatch(loginActions.setLoginErrors({ generic: `${i18.t<string>("an_error_has_occurred")}` }));
                }
                return false;
            })
            .finally(() => dispatch(loginActions.setLoginAsync(false)));
    };
};

const getMe = (): AsyncAction<Promise<boolean>> => {
    return async (dispatch, getState) => {

        dispatch(loginActions.setUserAsync(true));

        const me = await LoginApi.doMe();

        if (me) {
            dispatch(loginActions.setUser(me));

            const isLegalPerson = isUserLegalPerson(me);

            Sentry.setContext("user", {
                name: isLegalPerson ? me.title : `${me.firstName} ${me.lastName}`,
                type: isLegalPerson ? "Legal" : "Natural",
                id: me.id,
                email: me.email,
            });
        }

        dispatch(loginActions.setUserAsync(false));

        return me !== null;
    };
};

const updateNaturalPerson = ({
                                 userId,
                                 userForm,
                             }: {
    userId: string,
    userForm: INaturalPersonForm
}): AsyncAction<Promise<NaturalPerson | null>> => {
    return async (dispatch) => {

        dispatch(loginActions.setEditAsync(true));

        try {
            const r = await LoginApi.editNaturalPerson(userId, userForm);

            if (r) {
                dispatch(loginActions.setUser(r));
            }

            return r;
        } finally {
            dispatch(loginActions.setEditAsync(false));
        }
    };
};

const updateLegalPerson = ({
                               userId,
                               userForm,
                           }: {
    userId: string,
    userForm: ILegalPersonForm
}): AsyncAction<Promise<LegalPerson | null>> => {
    return async (dispatch) => {

        dispatch(loginActions.setEditAsync(true));

        try {
            const r = await LoginApi.editLegalPerson(userId, userForm);

            if (r) {
                dispatch(loginActions.setUser(r));
            }

            return r;
        } finally {
            dispatch(loginActions.setEditAsync(false));
        }
    };
};

const updateUser = ({
                        userId,
                        userForm,
                    }: { userId: string, userForm: IUserForm }): AsyncAction<Promise<CompoundUser | null>> => {
    return async (dispatch) => {

        dispatch(loginActions.setUserAsync(true));

        try {
            const r = await LoginApi.editUser(userId, userForm);

            if (r) {
                dispatch(loginActions.setUser(r));
            }

            return r;
        } finally {
            dispatch(loginActions.setUserAsync(false));
        }
    };
};

export const loginSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        setLoginAsync(state, { payload }: PayloadAction<boolean>) {
            state.loginAsync = payload;
        },
        setUserAsync(state, { payload }: PayloadAction<boolean>) {
            state.userAsync = payload;
        },
        setEditAsync(state, { payload }: PayloadAction<boolean>) {
            state.editAsync = payload;
        },
        setLoginErrors(state, { payload }: PayloadAction<LoginErrors>) {
            state.loginErrors = payload;
        },
        setUser(state, { payload }: PayloadAction<CompoundUser>) {
            state.user = payload;
        },
        updateEffectiveUser(state, { payload }: PayloadAction<Partial<CompoundUser>>) {
            Object.assign(state.user ?? {}, payload);
        },
        updateUser(state, { payload }: PayloadAction<Partial<CompoundUser>>) {
            Object.assign(state.user ?? {}, payload);
        },
        setSettings(state, { payload }: PayloadAction<ApplicationSettings | null>) {
            state.settings = payload;
        },
    },
});

export const loginActions = loginSlice.actions;
export const loginReducer = loginSlice.reducer;

export const loginAsyncActions = {
    performLogin,
    getMe,
    updateUser,
    updateLegalPerson,
    updateNaturalPerson,
};
