import React, { Component, ErrorInfo, ReactNode } from "react";
import i18n from "i18next";
import ErrorBoundaryButton from "./ErrorBoundaryButton";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <div className="flex-column justify-center align-center">
                <div className="text-color-chat-background mb-20">{i18n.t<string>("an_error_has_occurred")}</div>
                <ErrorBoundaryButton/>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
