import { LegalPerson } from "src/entities/LegalPerson/LegalPerson";
import { NaturalPerson } from "src/entities/NaturalPerson/NaturalPerson";
import { FullName, Utils } from "src/utils/utils";

export type CompoundUser = LegalPerson | NaturalPerson;

export function isUserLegalPerson(user: CompoundUser): user is LegalPerson {
    return (<LegalPerson>user).title !== undefined;
}

export function isUserNaturalPerson(user: CompoundUser): user is NaturalPerson {
    const naturalPerson = <NaturalPerson>user;
    return naturalPerson.firstName !== undefined && naturalPerson.lastName !== undefined;
}

export class CompoundUserUtils {
    public static getName(user: CompoundUser | null): string {
        if (!user) return "";

        return isUserLegalPerson(user) ? user.title : Utils.mergeName(user);
    }
    public static getCompoundUserFullName(user: CompoundUser | null): FullName {
        if (!user) {
            return {
                firstName: "N",
                lastName: "N",
            };
        }

        if (isUserLegalPerson(user)) {
            const split = user.title.split(" ");
            const last = split.pop();

            return {
                firstName: split.join(" "),
                lastName: last,
            };
        }

        return user;
    }
}
