import { FullName, Utils } from "src/utils/utils";
import { SelectFieldOption } from "nexon-react-ui";
import i18n from "src/i18n/config";

export interface AbstractUser {
    id: string,
    email: string,
    roles: UserRole[],
    createdAt: string,
}

export interface BasicUser {
    id: string,
    email: string,
}

export enum UserRole {
    ROLE_USER = "ROLE_USER",
    ROLE_APPLICANT = "ROLE_APPLICANT",
}

export enum Department {
    Service = "Service",
    Sales = "Sales",
    Parts = "Parts",
    Office = "Office",
}

export interface Colleague {
    id: string,
    firstName: string,
    lastName: string | null,
}

export interface User extends AbstractUser {
    firstName: string,
    lastName: string | null,
}

export interface UsersQuery {
    roles?: string;
    search?: string;
}

export class UserUtils {

    public static userRoleOptions: SelectFieldOption[] = [
        {
            label: i18n.t<string>("user"),
            value: UserRole.ROLE_USER as string,
        },
        // {
        //     label: i18n.t("super_admin"),
        //     value: UserRole.ROLE_SUPER_ADMIN,
        // },
    ];

    public static getUserRoleLabel(userRole: UserRole): string {
        if (userRole === UserRole.ROLE_USER) {
            return i18n.t<string>("user");
        }

        return "";
    }

    public static getDepartmentText(department?: Department | null): string {

        if (!department) {
            return "";
        }

        if (department === Department.Service) {
            return i18n.t<string>("service");
        } else if (department === Department.Sales) {
            return i18n.t<string>("sales");
        } else if (department === Department.Parts) {
            return i18n.t<string>("parts");
        } else if (department === Department.Office) {
            return i18n.t<string>("office");
        }

        return "";
    }

    public static isUser(user: User) {
        return user.roles.includes(UserRole.ROLE_USER);
    }

    public static getUserRole = (user: User): UserRole => {
        return UserRole.ROLE_USER;
    };

    public static getUserRoleText(user: User): string {
        const userRole = this.getUserRole(user);
        return this.userRoleOptions.find(o => o.value === userRole)?.label ?? "";
    };

    public static searchUsers(users: User[], searchVal: string): User[] {
        return users.filter(u => Utils.searchByName(u, searchVal));
    }

}
