import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer } from "./loginSlice";
import { themeReducer } from "./themeSlice";
import { applicationsReducer } from "./applicationsSlice";
import { competitionsReducer } from "./competitionsSlice";
import { competitionGroupsReducer } from "src/store/slices/competitionGroupsSlice";

const createRootReducer = () => combineReducers({
    login: loginReducer,
    theme: themeReducer,
    applications: applicationsReducer,
    competitions: competitionsReducer,
    competitionGroups: competitionGroupsReducer,
});

export default createRootReducer;
