import React from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import 'sweetalert2/dist/sweetalert2.css';
import 'react-image-lightbox/style.css';
import 'nexon-react-ui/assets/index.scss';
import 'react-circular-progressbar/dist/styles.css';

import './assets/index.scss';
import './assets/nexon-override.scss';

import App from './App';
import store from './store/store';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { IS_DEV, SENTRY_DSN, SERVER_ENV } from './utils/consts';
import { createRoot } from 'react-dom/client';
import history from './router/history';

if (!IS_DEV) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: SERVER_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
);
