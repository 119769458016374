import { BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { APIEntity } from "../../api/api";
import { Competition } from "src/entities/Competition/Competition";
import { ICompetitionForm } from "src/entities/Competition/ICompetitionForm";

interface CompetitionQuery {
}

interface CompetitionsState extends BaseEntityReducerState<Competition, CompetitionQuery> {

}

const initialState: CompetitionsState = {
    entities: {},
    perPage: 10,
    currentPage: 1,
    fetchAsync: false,
    didFetch: false,
    lastPage: 1,
    totalItems: 0,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    query: {},
    searchFields: [],
};

const slice = createEntityReducer({} as Competition, APIEntity.Competitions, initialState, {
    resetState() {
        return initialState;
    },
}, builder => {
}, {} as ICompetitionForm, {} as CompetitionQuery);

export const {
    slice: competitionsSlice,
    asyncActions: competitionsAsyncActions,
} = slice;

const competitionsListSelector = (state: RootState) => state.competitions.entities;

export const competitionsList = createSelector([competitionsListSelector], (entities) => {
    return Object.values(entities);
});

export const competitionsOptions = createSelector([competitionsList], (entities) => {
    return entities.map(ent => {
        return {
            label: ent.title,
            value: `/api/competitions/${ent.id}`,
        };
    });
});

function selectedCompetition(state: RootState): Competition | null {
    return state.competitions.selectedEntities.length === 1 ? (state.competitions.entities[state.competitions.selectedEntities[0]] ?? null) : null;
}

export const competitionsSelectors = {
    competitionsList,
    selectedCompetition,
    competitionsOptions,
};

export const competitionsActions = competitionsSlice.actions;
export const competitionsReducer = competitionsSlice.reducer;
