import React from "react";
import "./header.scss";
import { Utils } from "src/utils/utils";
import { useAppSelector } from "src/store/hooks";
import { loginSelectors } from "src/store/slices/loginSelectors";
import Navbar from "./Navbar/Navbar";
import { CompoundUserUtils } from "src/entities/CompoundUser/CompoundUser";

function Header() {
    const user = useAppSelector(loginSelectors.user);

    const userName = Utils.mergeName(CompoundUserUtils.getCompoundUserFullName(user));

    return (
        <div className="app-header">
            <div className="header-content flex-column">
                <div className="flex-row flex-1 w-100p justify-space-between align-center">
                    <div>
                        <div className="user-name">{userName}</div>
                        <div className="text-14 text-color-gray">{user?.email}</div>
                    </div>
                    <Navbar/>
                </div>
            </div>
        </div>
    );
}

export default Header;
