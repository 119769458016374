import React, { FormEvent, useCallback, useEffect, useState } from "react";
import "./login.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { loginActions, loginAsyncActions, LoginErrors } from "src/store/slices/loginSlice";
import { Router } from "src/router/router";
import { Button, ButtonStyle, ButtonType, InputField, InputType } from "nexon-react-ui";
import IconSax from "../../components/IconSax/IconSax";
import { Utils } from "src/utils/utils";
import InputFieldWithValidationIcon from "../../components/InputFieldWithValidationIcon/InputFieldWithValidationIcon";
import { ReactComponent as SvgLogo } from "../../assets/images/fmslu.svg";
import { API } from "src/api/api";

import DownLeft from "../../assets/images/S_Lijevo_dole.svg";
import UpLeft from "../../assets/images/S_Lijevo_gore.svg";
import DownRight from "../../assets/images/S_Desno_dole.svg";
import UpRight from "../../assets/images/S_Desno_gore.svg";
import CenterBottom from "../../assets/images/S_Centar_dole.svg";
import { SwalUtils } from "src/utils/swal_utils";
import { getYear } from "date-fns";

const Login = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [displayForgotPasswordSubmitForm, setDisplayForgotPasswordSubmitForm] = useState(false);
    const [displayForgotPasswordRequestForm, setDisplayForgotPasswordRequestForm] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loginAsync = useAppSelector((state) => state.login.loginAsync);
    const loginErrors = useAppSelector((state) => state.login.loginErrors);

    const validateNewPassword = useCallback((): boolean => {
        const retErrors: LoginErrors = {};

        const regexTest = newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);

        if (!regexTest) {
            retErrors.password = t<string>("password_you_entered_is_not_valid") + ".";
        }

        if (newPassword !== confirmPassword) {
            retErrors.password = t<string>("passwords_dont_match") + ".";
        }

        if (!newPassword?.length) {
            retErrors.password = t<string>("enter_your_password") + ".";
        }

        dispatch(loginActions.setLoginErrors(retErrors));
        return Object.keys(retErrors).length === 0;
    }, [newPassword, confirmPassword, dispatch, t]);

    const validateForm = useCallback((): boolean => {
        const errors: LoginErrors = {};

        if (!email?.length) {
            errors.email = t<string>("enter_your_email") + ".";
        } else if (!Utils.validEmail(email)) {
            errors.email = t<string>("invalid_email");
        }

        if (!password?.length) {
            errors.password = t<string>("enter_your_password") + ".";
        } else if (password.length < 8) {
            errors.password = t<string>("password_is_too_short_min_8_characters");
        }

        dispatch(loginActions.setLoginErrors(errors));

        return Object.keys(errors).length === 0;
    }, [dispatch, email, password, t]);

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [email, password]);

    const onSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        if (email && password) {
            const success = await dispatch(loginAsyncActions.performLogin(email, password));

            if (success) {
                navigate(Router.RoutePaths.Competitions, {
                    replace: true,
                });
            }
        }

    }, [navigate, email, password, dispatch, validateForm]);

    const onClickCreateAccount = () => {
        navigate(Router.RoutePaths.Register);
    };

    const onClickForgotPassword = () => {
        window.location.href = `${API.endpoint}/public/reset-password`;
    };

    const onClickSendEmail = () => {
        setDisplayForgotPasswordSubmitForm(true);
    };

    const renderForgotPasswordRequestForm = () => {
        return (
            <>
                <Button onClick={() => {
                }}
                        className="back"
                        buttonStyle={ButtonStyle.Text}>
                    <IconSax icon="arrow-left" className="mr-10"/>

                    {t<string>("back")}
                </Button>
                <InputFieldWithValidationIcon onChange={e => setEmail(e.target.value)}
                                              label={t<string>("email_address")}
                                              error={loginErrors.email}
                                              value={email}
                                              name={"email"}/>

                <Button className="send-email-btn"
                        loading={loginAsync}
                        onClick={onClickSendEmail}
                        buttonStyle={ButtonStyle.Purple}
                        disabled={!email}>
                    {t<string>("continue")}
                </Button>

                <div className="text-color-chat-background text-14">
                    {t<string>("instructions_for_resolving_the_forgotten_password_will_be_sent_to_the_entered_email_address")}.
                </div>
            </>
        );
    };


    const renderForgotPasswordSubmitForm = () => {
        return (
            <div className="forgot-password-form">
                <Button onClick={() => {
                }}
                        className="back"
                        buttonStyle={ButtonStyle.Text}>
                    <IconSax icon="arrow-left" className="mr-10"/>

                    {t<string>("back")}
                </Button>
                <div className="title">{t<string>("forgot_password")}</div>
                <div className="password-restrictions">
                    {t<string>("password_must_contain_minimum_eight_characters_at_least_one_number_and_one_uppercase_letter")}
                </div>
                <InputField onChange={e => setNewPassword(e.target.value)}
                            label={t<string>("new_password")}
                            type={InputType.Password}
                            value={newPassword}
                            name={"new-password"}
                            error={loginErrors.password}/>
                <InputField onChange={e => setConfirmPassword(e.target.value)}
                            label={t<string>("confirm_new_password")}
                            type={InputType.Password}
                            value={confirmPassword}
                            name={"confirm-password"}
                            error={loginErrors.password}
                            showErrorText={true}/>
                <Button className="sign-in-btn"
                        loading={loginAsync}
                        onClick={() => {
                            validateNewPassword();
                        }}>
                    {t<string>("continue")}
                </Button>
            </div>
        );
    };

    return (
        <>
            <div className="login page">
                <SvgLogo className={"app-logo"}/>
                {!displayForgotPasswordSubmitForm ? (
                    <div className="outer-container">
                        <form className="inner-container" onSubmit={onSubmit}>

                            {!displayForgotPasswordRequestForm ? (
                                <>
                                    <InputFieldWithValidationIcon onChange={e => setEmail(e.target.value)}
                                                                  label={t<string>("email_address")}
                                                                  error={loginErrors.email}
                                                                  value={email}
                                                                  name={"email"}/>
                                    <InputFieldWithValidationIcon onChange={e => setPassword(e.target.value)}
                                                                  label={t<string>("password")}
                                                                  error={loginErrors.password}
                                                                  type={InputType.Password}
                                                                  value={password}
                                                                  name={"password"}/>

                                    <span className="forgot-pass-btn text-btn"
                                          onClick={onClickForgotPassword}>
                                        {t<string>("forgot_your_password") + "?"}
                                    </span>

                                    <Button className="login-btn"
                                            type={ButtonType.Submit}
                                            buttonStyle={isFormValid ? ButtonStyle.Green : ButtonStyle.Orange}
                                            loading={loginAsync}>
                                        {t<string>("sign_in")}
                                    </Button>

                                    <Button className="new-user-btn"
                                            type={ButtonType.Submit}
                                            buttonStyle={ButtonStyle.Orange}
                                            onClick={onClickCreateAccount}>
                                        {t<string>("new_user") + "? " + t<string>("create_account")}
                                    </Button>
                                </>
                            ) : (
                                renderForgotPasswordRequestForm()
                            )}
                            <span className="error-msg">{loginErrors.generic}</span>
                        </form>
                    </div>
                ) : (
                    renderForgotPasswordSubmitForm()
                )}
                <div className="flex-column text-10 mt-auto mb-28 align-center  ">
                    <div>
                        ©{getYear(new Date())} Fondacija za muzičke, scenske i likovne umjetnosti. All rights reserved.
                    </div>
                </div>

            </div>

            <img src={DownLeft} id="DownLeft" className="svg-art svg-art-down-left"/>
            <img src={DownRight} id="DownRight" className="svg-art svg-art-down-right"/>
            <img src={UpLeft} id="UpLeft" className="svg-art svg-art-up-left"/>
            <img src={UpRight} id="UpRight" className="svg-art svg-art-up-right"/>
        </>
    );
};

export default Login;
