import { BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { APIEntity } from "src/api/api";
import { CompetitionGroup, CompetitionGroupForm } from "src/entities/CompetitionGroup/CompetitionGroup";


interface CompetitionGroupQuery {
}

interface CompetitionGroupsState extends BaseEntityReducerState<CompetitionGroup, CompetitionGroupQuery> {

}

const initialState: CompetitionGroupsState = {
    entities: {},
    perPage: 10,
    currentPage: 1,
    fetchAsync: false,
    didFetch: false,
    lastPage: 1,
    totalItems: 0,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    query: {},
    searchFields: [],
};

const slice = createEntityReducer({} as CompetitionGroup, APIEntity.CompetitionGroups, initialState, {
    resetState() {
        return initialState;
    },
}, builder => {
}, {} as CompetitionGroupForm, {} as CompetitionGroupQuery);

export const {
    slice: competitionGroupsSlice,
    asyncActions: competitionGroupsAsyncActions,
} = slice;

const competitionGroupsListSelector = (state: RootState) => state.competitionGroups.entities;

export const competitionGroupsList = createSelector([competitionGroupsListSelector], (entities) => {
    return Object.values(entities);
});

export const competitionGroupsOptions = createSelector([competitionGroupsList], (entities) => {
    return entities.map(ent => {
        return {
            label: ent.name,
            value: `/api/competition_groups/${ent.id}`,
        };
    });
});

function selectedCompetitionGroup(state: RootState): CompetitionGroup | null {
    return state.competitionGroups.selectedEntities.length === 1 ? (state.competitionGroups.entities[state.competitionGroups.selectedEntities[0]] ?? null) : null;
}

export const competitionGroupsSelectors = {
    competitionGroupsList,
    selectedCompetitionGroup,
    competitionGroupsOptions,
};

export const competitionGroupsActions = competitionGroupsSlice.actions;
export const competitionGroupsReducer = competitionGroupsSlice.reducer;
