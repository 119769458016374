export enum StorageKeys {
    Token = "fmslu_token",
    RefreshToken = "fmslu_refresh_token",
    Language = "fmslu__language",
    Theme = "fmslu__theme",
}


export enum RegisterFormStorageKeys {
    AddressStreet = "address-street",
    ApplicantType = "applicant-type",
    BankContract = "bank-contract",
    BankContractDeleteToken = "bank-contract-delete-token",
    BankContractIssuedAt = "bank-contract-issued-at",
    Cips = "cips",
    CipsDeleteToken = "cips-delete-token",
    CipsIssuedAt = "cips-issued-at",

    CurrentCourtRegisterExtract = "current-court-register-extract",
    CurrentCourtRegisterExtractDeleteToken = "current-court-register-extract-delete-token",
    CurrentCourtRegisterExtractIssuedAt = "current-court-register-extract-issued-at",

    BankCertificateOfSolvency = "bank-certificate-of-solvency",
    BankCertificateOfSolvencyDeleteToken = "bank-certificate-of-solvency-delete-token",
    BankCertificateOfSolvencyIssuedAt = "bank-certificate-of-solvency-issued-at",

    IdCardBack = "id-card-back",
    IdCardBackDeleteToken = "id-card-back-delete-token",
    IdCardFront = "id-card-front",
    IdCardFrontDeleteToken = "id-card-front-delete-token",
    IdCardIssuedAt = "id-card-issued-at",
    LegalPersonName = "legal-person-name",
    NaturalPersonName = "natural-person-name",
    PreviousYearBalanceSheetAndIncomeStatement = "previous-year-balance-sheet-and-income-statement",
    PreviousYearBalanceSheetAndIncomeStatementDeleteToken = "previous-year-balance-sheet-and-income-statement-delete-token",
    PreviousYearBalanceSheetAndIncomeStatementIssuedAt = "previous-year-balance-sheet-and-income-statement-issued-at",
    RegisterCity = "register-city",
    RegisterCountry = "register-country",
    RegisterEmail = "register-email",
    RegisterPhone = "register-phone",
    IdDocument = "registration-doc",
    IdDocumentDeleteToken = "registration-doc-delete-token",
    IdDocumentIssuedAt = "registration-document-issued-at",
    StatuteOrFoundingDecision = "statute-or-founding-decision",
    StatuteOrFoundingDecisionDeleteToken = "statute-or-founding-decision-delete-token",
    StatuteOrFoundingDecisionIssuedAt = "statute-or-founding-decision-issued-at",

    JMBG = "jmbg",
    AuthorizedPerson = "authorized-person",
    IDNumber = "id-number",
    BankName = "bank-name",
    TransactionAccountNumber = "transaction-account-number",
    TypeOfIncome = "type-of-income",
    County = "county",
    BudgetOrganisation = "budget-organisation"
}
