import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "nexon-react-ui";
import { useTranslation } from "react-i18next";

export interface ErrorBoundaryButtonProps {

}

function ErrorBoundaryButton({}: ErrorBoundaryButtonProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Button onClick={() => navigate(-1)}>
            {t<string>("go_back")}
        </Button>
    );
}

export default ErrorBoundaryButton;
