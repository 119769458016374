import Swal, {SweetAlertIcon, SweetAlertOptions} from "sweetalert2";
import i18next from "i18next";

export class SwalUtils {
    public static showSwalToast(title: string, message: string, icon: SweetAlertIcon, options?: SweetAlertOptions): void {
        Swal.fire({
            ...{
                toast: true,
                position: "top-end",
                timer: 4000,
                title,
                text: message,
                icon,
                showConfirmButton: false,
                customClass: {
                    popup: "nexon-swal-toast"
                },
            }, ...options
        });
    }

    public static showWarningSwalToast(message: string, options?: SweetAlertOptions): void {
        SwalUtils.showSwalToast(i18next.t("warning"), message, "warning", options);
    }

    public static showErrorSwalToast(message: string, options?: SweetAlertOptions): void {
        SwalUtils.showSwalToast(i18next.t("error"), message, "error", options);
    }

    public static showSuccessSwalToast(message: string, options?: SweetAlertOptions): void {
        SwalUtils.showSwalToast(i18next.t("successfully_done"), message, "success", options);
    }
}
