import { AsyncAction, BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { APIEntity } from "src/api/api";
import { Application } from "src/entities/Application/Application";
import { ApplicationFormUtils, IApplicationForm } from "src/entities/Application/IApplicationForm";

interface ApplicationQuery {
}

interface ApplicationsState extends BaseEntityReducerState<Application, ApplicationQuery> {
}

const initialState: ApplicationsState = {
    entities: {},
    perPage: 10,
    currentPage: 1,
    fetchAsync: false,
    didFetch: false,
    lastPage: 1,
    totalItems: 0,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    query: {},
    searchFields: [],
};

function createApplicationForCompetition(competitionId: string): AsyncAction<Promise<Application | null>> {
    return async (dispatch) => {
        const form = {
            competition: `/api/competitions/${competitionId}`,
            value: {
                givenValue: "0",
                requestedValue: "0",
                totalValue: "0",
            },
        };

        return await dispatch(applicationsAsyncActions.createEntity(ApplicationFormUtils.prepareForm(form)));
    }
}

const slice = createEntityReducer({} as Application, APIEntity.Applications, initialState, {
    resetState() {
        return initialState;
    },
}, builder => {}, {} as IApplicationForm, {} as ApplicationQuery);

export const {
    slice: applicationsSlice,
} = slice;

export const applicationsAsyncActions = {
    ...slice.asyncActions,
    createApplicationForCompetition,
};

const applicationsListSelector = (state: RootState) => state.applications.entities;

export const applicationsList = createSelector([applicationsListSelector], (entities) => {
    return Object.values(entities);
});

function selectedApplication(state: RootState): Application | null {
    return state.applications.selectedEntities.length === 1 ? (state.applications.entities[state.applications.selectedEntities[0]] ?? null) : null;
}

export const applicationsSelectors = {
    applicationsList,
    selectedApplication,
};

export const applicationsActions = applicationsSlice.actions;
export const applicationsReducer = applicationsSlice.reducer;
