import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "nexon-react-ui";
import { useTranslation } from "react-i18next";
import useQuery from "src/utils/hooks/useQuery";

function ReloadPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const query = useQuery();

    useEffect(() => {
        const to = query.get("to");
        if (!to) return;

        localStorage.setItem("reload-page", to);

    }, [query]);

    return (
        <div className="flex-column flex-center-both text-color-chat-background">
            <div className="text-20 text-bold">Ova stranica trenutno nije dostupna</div>
            <div>Uzrok mogu biti tehničke pogreške na čijem otklanjanju radimo. Pokušajte ponovo učitati ovu stranicu.</div>

            <Button
                onClick={() => {
                    localStorage.removeItem("reload-page");
                    navigate(-1);
                }}
                className="mt-36">
                Ponovo učitaj stranicu
            </Button>
        </div>
    );
}

export default ReloadPage;
