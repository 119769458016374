export interface Route {
    path: string,
    name: RouteName,
}

export enum RouteName {
    Competitions = "Competitions",
    Login = "Login",
    ForgotPassword = "ForgotPassword",
    ResetPassword = "ResetPassword",
    NoBoard = "NoBoard",
    Applicants = "Applicants",
    Applications = "Applications",
    Register = "Register",
    Documents = "Documents",
    ApplyToCompetition = "ApplyToCompetition",
    MyProfile = "MyProfile",
    Support = "Support",
    ReloadPage = "ReloadPage",
    HiddenBugRoute = "HiddenBugRoute",
}

export class Router {

    public static readonly RoutePaths = {
        Login: "/login",
        ForgotPassword: "/forgot_password",
        ResetPassword: "/reset_password",
        Competitions: "/",
        NoBoard: "/noboard",
        Applicants: "/applicants",
        Applications: "/applications",
        Register: "/register",
        Documents: "/documents",
        ApplyToCompetitionWithApplicationId: "/apply-to-competition/:id/:applicationId",
        MyProfile: "/profile",
        Support: "/support",
        ReloadPage: "/reload-page",
        HiddenBugRoute: "/hidden-bug-route",
    };

    public static applyToCompetitionWithId(id: string, applicationId: string): string {
        return this.RoutePaths
            .ApplyToCompetitionWithApplicationId
            .replace(":id", id)
            .replace(":applicationId", applicationId);
    }

    public static readonly routes: Array<Route> = [
        { path: Router.RoutePaths.Login, name: RouteName.Login },
        { path: Router.RoutePaths.ResetPassword, name: RouteName.ResetPassword },
        { path: Router.RoutePaths.ForgotPassword, name: RouteName.ForgotPassword },
        { path: Router.RoutePaths.Competitions, name: RouteName.Competitions },
        { path: Router.RoutePaths.NoBoard, name: RouteName.NoBoard },
        { path: Router.RoutePaths.Applicants, name: RouteName.Applicants },
        { path: Router.RoutePaths.Applications, name: RouteName.Applications },
        { path: Router.RoutePaths.Register, name: RouteName.Register },
        { path: Router.RoutePaths.Documents, name: RouteName.Documents },
        { path: Router.RoutePaths.MyProfile, name: RouteName.MyProfile },
        { path: Router.RoutePaths.ReloadPage, name: RouteName.ReloadPage },
        { path: Router.RoutePaths.HiddenBugRoute, name: RouteName.HiddenBugRoute },
        { path: Router.RoutePaths.ApplyToCompetitionWithApplicationId, name: RouteName.ApplyToCompetition },
        { path: Router.RoutePaths.Support, name: RouteName.Support },
    ];
}
