import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { Theme } from "src/i18n/config";
import { StorageKeys } from "src/utils/storage_keys";

export interface LoginState {
    theme: Theme
}

const initialState: LoginState = {
   theme: (localStorage.getItem(StorageKeys.Theme) ?? "light") as Theme
};

export const themeSlice = createSlice({
    name: "theme",
    initialState: initialState,
    reducers: {
        setTheme(state, { payload }: PayloadAction<Theme>) {
            state.theme = payload;
        },
    },
});


const themeSelector = (state: RootState) => state.theme.theme;
export const themeActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;

export const themeSelectors = {
    theme: themeSelector,
};

export const themeAsyncActions = {  };
