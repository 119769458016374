import { bs, enUS } from "date-fns/locale";
import { SelectFieldOption, ToggleSwitchProps } from "nexon-react-ui";
import { differenceWith, isEqual, isObject, transform } from "lodash";
import Decimal from "decimal.js";
import { Language } from "../i18n/config";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { dinero, toDecimal } from "dinero.js";
import { Currency } from "@dinero.js/currencies";
import { Document } from "src/entities/Document/Document";
import { addDays, isAfter, isBefore } from "date-fns";
import { CompetitionGroup } from "../entities/CompetitionGroup/CompetitionGroup";

export let dateFnsLocale = enUS;

export type ReadFile = { file: string, mimeType: string, name: string, id: string, abortController?: AbortController };
export type FullName = { firstName?: string | null, lastName?: string | null };

export const toggleSwitchStyleProps: Partial<ToggleSwitchProps> = {
    color: {
        checked: "var(--mainColor)",
        unchecked: "#d2d2d2",
    },
    switchColor: {
        unchecked: "#f5f5f5",
        checked: "#FFFFFF",
    },
};

export function transformStringToFullName(str: string): FullName {
    let [first, ...rest] = str.split(" ");
    let second = rest.join("_");

    return {
        firstName: first ?? "",
        lastName: second ?? "",
    };

}

export function changeDateFnsLocaleBasedOnLang(lang: Language) {
    switch (lang) {
        case Language.en:
            dateFnsLocale = enUS;
            break;
        case Language.bs:
            dateFnsLocale = bs;
            break;
    }
}

export function documentNotExpired(document: Document, validDays: number) {
    if (validDays === 0) return true;
    if (!document.issuedAt) return false;

    const validUntil = addDays(new Date(document.issuedAt), validDays);

    return isAfter(validUntil, new Date());
}

export function extractOldestExpiresAtDateFromCompetitionGroup(group: CompetitionGroup) {
    let oldest = group.expiresAt;
    group.competitions.forEach((competition) => {
        if (competition.expiresAt !== null && isAfter(new Date(competition.expiresAt), new Date(oldest))) {
            oldest = competition.expiresAt;
        }
    });
    return oldest;
}

export class Utils {

    public static getNumWordsInString(text: string): number {
        return text.split(" ").filter(b => b.length > 0).length;
    }

    public static capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public static keyToTitleCase(key?: string, keyWordsDelimiter = "_") {

        if (!key) {
            return "";
        }

        return this.capitalize(key.replaceAll(keyWordsDelimiter, " "));
    }


    public static createSelectFieldOptionsFromArray = (array: { name: string, id: string }[], idPrefix?: string) => {
        const options: SelectFieldOption[] = [];

        array.forEach(c => {
            options.push({
                label: c.name,
                value: idPrefix ? idPrefix + c.id : c.id,
            });
        });

        return options;
    };


    public static parseJSON<T = Record<string, unknown>>(json: string): T | null {
        try {
            return JSON.parse(json);
        } catch (ex) {
            return null;
        }
    }

    public static genArrayFromTo(from: number, to: number, step = 1, inclusive = false, skipFirst = false): Array<number> {
        let out = [];

        for (let i = from; inclusive ? (i <= to) : (i < to); i += step) {
            if (skipFirst && i === from) continue;

            out.push(i);
        }

        return out;
    }

    public static readFile(file: File): Promise<ReadFile> {
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () => {
                if (fr.result) {
                    if (typeof fr.result === "string") {
                        resolve({ mimeType: file.type, file: fr.result, name: file.name, id: Utils.getRandomId() });
                    }
                } else {
                    reject();
                }
            };
            fr.onerror = reject;
            fr.readAsDataURL(file);
        });
    }

    public static dataURItoBlob(dataURI: string): Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(",")[1]);

        // separate out the mime component
        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //New Code
        return new Blob([ab], { type: mimeString });
    }

    public static immerCompatibleObjectAssign<T>(source: T, updater: Partial<T>) {
        for (const [key, value] of Object.entries(updater)) {
            (source as any)[key] = value;
        }
    }

    public static convertFullNameToLastAndFirst(val: string): FullName {

        const trimmed = val.trim();

        const split = trimmed.split(" ");

        const lastName = split.slice(0, split.length - 1).join(" ");
        const firstName = split[split.length - 1] ?? "";

        return {
            lastName,
            firstName,
        };
    }

    public static convertFullNameToFirstAndLast(val: string): FullName {

        const trimmed = val.trim();

        const i = trimmed.indexOf(" ");
        const split = i === -1 ? [trimmed] : [trimmed.slice(0, i), trimmed.slice(i + 1)];

        let firstName = split[0];
        let lastName = split[1];

        if (!firstName && i !== -1) {
            firstName = "";
        }

        if (!lastName && i !== -1) {
            lastName = "";
        }

        return {
            firstName,
            lastName,
        };
    }

    public static rad2deg(radians: number) {
        return radians * (180 / Math.PI);
    }

    public static remnum2px(rem: number): number {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    public static rem2px(rem: string): number {
        const remNum = +(rem.replace("rem", " ").trim());
        return this.remnum2px(remNum);
    }

    public static mergeName({ lastName, firstName }: FullName): string {
        return [firstName, lastName].filter(v => typeof v === "string").join(" ");
    }

    public static searchByName(fullName: FullName, searchVal: string): boolean {
        return Utils.mergeName(fullName).toLowerCase().includes(searchVal.toLowerCase());
    }

    public static findSelectOption(options: SelectFieldOption[], value?: unknown): SelectFieldOption | null {
        return options.find(o => o.value === value) ?? null;
    }

    public static getObjectDiff(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {

        function changes(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {
            return transform(object, function (result, value, key) {
                if (!isEqual(value, base[key])) {
                    result[key] = (isObject(value) && isObject(base[key])) ? changes(value as Record<string, unknown>, base[key] as Record<string, unknown>) : value;
                }
            });
        }

        return changes(object, base);
    }

    public static getArrayDifferenceByObjectProp<T>(source: Array<T>, target: Array<T>) {
        return differenceWith(source, target, isEqual);
    }

    public static clearWhitespace(s: string): string {
        return s.replaceAll(/\s/g, "");
    }

    public static priceFromCents(priceInCents: number): string {
        return new Decimal(priceInCents).div(100).toFixed(2);
    }

    public static priceToCents(price: number): number {
        return Number(new Decimal(price).mul(100));
    }

    public static isPhoneValid(phone: string): boolean {
        return isPossiblePhoneNumber(phone) && isValidPhoneNumber(phone);
    }

    public static formatPrice(currency: string, price: number): string {
        if (currency === "RSD") {
            return new Intl.NumberFormat("sr-RS", {
                style: "currency",
                currency: "RSD",
            }).formatToParts(price).map(
                p => p.type !== "literal" && p.type !== "currency" ? p.value : "",
            ).join("");
        }

        return price.toString();
    }

    static getBasedPrice(price: number, currency: Currency<number>): number {
        return new Decimal(price).mul(Math.pow(10, currency.exponent)).toNumber();

    }

    static getParsedPrice(price: number | null, currency: Currency<number>): string {

        if (!price && price !== 0) {
            return "N/A";
        }

        const final = dinero({ amount: price, currency: currency });

        return toDecimal(final, ({ value, currency }) => `${value}`);
    }

    public static getRandomId(length = 10): string {
        return Math.random().toString(36).replace(/[^a-z]+/g, "").slice(0, length);
    }

    // we assume fullName is firstName + lastName or full name if customer is a firm
    public static reverseFullname(fullName: string): string {
        const split = fullName.split(" ");
        if (split.length > 2) {
            return fullName;
        }
        const firstName = split[0] ?? "";
        const lastName = split[1] ?? "";

        return lastName + " " + firstName;
    }

    public static randomIntFromInterval(min: number, max: number) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    public static randomIntFromIntervalSeeded(seed: number, min: number, max: number) { // min and max included
        return Math.floor(seed * (max - min + 1) + min);
    }

    public static numDigits(x: number): number {
        return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
    }

    public static hashCode(str: string) {
        let seed = 12312;
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };

    public static generateRandomColorHslOpaque(seed: number, opacity: number) {
        const correctedSeed = Math.abs(seed / Math.pow(10, seed.toString().length));

        const hue = Math.floor(correctedSeed * 360);
        const saturation = Utils.randomIntFromIntervalSeeded(correctedSeed, 40, 100) + "%";
        const lightness = Utils.randomIntFromIntervalSeeded(correctedSeed, 20, 80) + "%";
        return "hsla(" + hue + ", " + saturation + ", " + lightness + `, ${opacity})`;
    }

    public static generateRandomColorHsl() {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Utils.randomIntFromInterval(50, 100) + "%";
        const lightness = Utils.randomIntFromInterval(20, 80) + "%";
        return "hsla(" + hue + ", " + saturation + ", " + lightness + ", 0.5)";
    }

    public static validURL(str: string): boolean {
        const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
        return pattern.test(str);
    }

    public static camelToSnakeCase(str: string) {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    };

    public static validEmail(str: string): boolean {
        //RFC 5322 Official Standard
        const pattern = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
        return pattern.test(str);
    }

    public static validPassword(str: string): boolean {
        //Password must contain minimum eight characters at least one number and one uppercase letter
        const regexTest = str.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/);

        return !regexTest;
    }

    public static isValidUUID(str: string): boolean {
        // Regular expression to check if string is a valid UUID
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    public static getUTCZeroToday(): Date {
        const d = new Date();
        d.setUTCHours(0, 0, 0, 0);
        return d;
    }
}
