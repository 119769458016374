import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import * as currencies from "@dinero.js/currencies";
import { UserRole } from "src/entities/User/User";

const effectiveUser = (state: RootState) => state.login.user;
const userSelector = (state: RootState) => state.login.user;

//TODO currently fixed BAM
const selectedCurrency = createSelector([userSelector], (user) => {
    const currency = "BAM";

    return currencies[currency as keyof typeof currencies];
});

//TODO currently fixed KM
const currencySymbol = createSelector([userSelector], (user) => {
    return "KM";
});

const isApplicant = createSelector([effectiveUser], (user) => {
    return user?.roles.includes(UserRole.ROLE_APPLICANT);
});

const applicationSettings = (state: RootState) => state.login.settings;

export const loginSelectors = {
    user: userSelector,
    effectiveUser,
    selectedCurrency,
    currencySymbol,
    isApplicant,
    applicationSettings
};
