import { API, ApiMethod } from "./api";
import { StorageKeys } from "../utils/storage_keys";
import { IUserForm } from "src/entities/User/UserForm";
import { CompoundUser } from "src/entities/CompoundUser/CompoundUser";
import { ILegalPersonForm } from "src/entities/LegalPerson/ILegalPersonForm";
import { LegalPerson } from "src/entities/LegalPerson/LegalPerson";
import { INaturalPersonForm } from "src/entities/NaturalPerson/INaturalPersonForm";
import { NaturalPerson } from "src/entities/NaturalPerson/NaturalPerson";

export interface LoginResponse {
    token: string;
    refresh_token: string;
}

export class LoginApi {
    public static doLogin = async (email: string, password: string): Promise<LoginResponse | void> => {
        return API.jsonApiCall<LoginResponse>({
            url: API.login,
            method: ApiMethod.Post,
            noAuth: true,
            jsonData: {
                email,
                password,
            },
        }).then(r => {
            if (r != null) {
                localStorage.setItem(StorageKeys.Token, r.token);
                localStorage.setItem(StorageKeys.RefreshToken, r.refresh_token);
            }
        });
    };

    public static doMe = async (impersonateEmail: string | null = null): Promise<CompoundUser | null> => {
        let url = new URL(API.me);

        if (impersonateEmail) {
            url.searchParams.append("impersonate", impersonateEmail);
        }

        return API.jsonApiCall<CompoundUser>({
            url: url.toString(),
            method: ApiMethod.Get,
        });
    };

    public static editLegalPerson = async (userId: string, data: ILegalPersonForm): Promise<LegalPerson | null> => {
        return await API.jsonApiCall<LegalPerson>({
            url: API.legalPeople + `/${userId}`,
            method: ApiMethod.Put,
            jsonData: data as unknown as Record<string, unknown>,
        })
    }

    public static editNaturalPerson = async (userId: string, data: INaturalPersonForm): Promise<NaturalPerson | null> => {
        return await API.jsonApiCall<NaturalPerson>({
            url: API.naturalPeople + `/${userId}`,
            method: ApiMethod.Put,
            jsonData: data as unknown as Record<string, unknown>,
        })
    }

    public static editUser = async (userId: string, data: IUserForm): Promise<CompoundUser | null> => {
        return await API.jsonApiCall<CompoundUser>({
            url: API.users + `/${userId}`,
            method: ApiMethod.Put,
            jsonData: data as unknown as Record<string, unknown>,
        })
    }

    public static changeUserPassword = async (userId: string, password: string): Promise<CompoundUser> => {
        return API.apiCall({
            url: API.users + `/${userId}/change_password`,
            method: ApiMethod.Put,
            jsonData: {
                password,
            },
        }).then(r => {
            return r?.json();
        });
    };
}
