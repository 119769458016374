import React from "react";
import "./input_field_with_validation_icon.scss";
import { InputField } from "nexon-react-ui";
import { InputProps } from "nexon-react-ui/lib/components/InputField/InputField";
import IconIcoMoon from "../IconIcoMoon/IconIcoMoon";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

export interface InputFieldWithValidationIconProps extends Omit<InputProps, "value"> {
    value?: string | ReadonlyArray<string> | number | undefined | null;
    errorIsTooltip?: boolean,
    className?: string,
}

function InputFieldWithValidationIcon({
                                          errorIsTooltip = true,
                                          className,
                                          value,
                                          ...props
                                      }: InputFieldWithValidationIconProps) {

    const displayIcon = value !== null;

    const Icon = props.error ? (
        <>
            <IconIcoMoon icon={"cross-small"}
                         className="error-icon"
                         data-tip={props.error}
                         data-for={"error-text"}/>
            <ReactTooltip id={"error-text"} place={"bottom"}></ReactTooltip>
        </>
    ) : (
        <IconIcoMoon icon={"check"} className="success-icon"/>
    );

    return (
        <InputField {...props}
                    value={value ?? ""}
                    className={classNames("input-field-with-validation-icon", className)}
                    renderCustomIcon={displayIcon && errorIsTooltip ? Icon : undefined}
                    error={displayIcon || errorIsTooltip ? props.error : undefined}
                    showErrorText={!errorIsTooltip}/>
    );
}

export default InputFieldWithValidationIcon;
